import gql from "graphql-tag";
import { Membership } from "graphql-client/fragments/membership";
import { SessionUser } from "@RHCommerceDev/graphql-client/fragments/session-user";

export const queryGetSession = gql`
  query GetSession {
    getSession {
      sessionId
      rhuid
    }
  }
`;

export const queryMembership = gql`
  query Membership($userId: String, $region: String) {
    membership(userId: $userId, region: $region) {
      ...Membership
    }
  }
  ${Membership}
`;

export const createAnonymousUserMutation = gql`
  mutation CreateAnonymousUser {
    createAnonymousUser {
      id
      firstName
      lastName
      email
      userType
      gender
      sfContactId
      sfAccountType
      company
      shopperRef
      createdDate
      locale
      defaultShippingAddressId
    }
  }
`;

export const createUserMutation = gql`
  mutation CreateUser($userInput: UserInput!) {
    createUser(userInput: $userInput) {
      id
      firstName
      lastName
      email
      userType
      gender
      sfContactId
      sfAccountType
      company
      shopperRef
      createdDate
      locale
      defaultShippingAddressId
    }
  }
`;

export const updateUserMutation = gql`
  mutation UpdateUser($userInput: UpdateUserInput!) {
    updateUser(userInput: $userInput) {
      id
      firstName
      lastName
      email
      userType
      gender
      sfContactId
      sfAccountType
      company
      shopperRef
      createdDate
      locale
      defaultShippingAddressId
    }
  }
`;

export const updateSessionMutation = gql`
  mutation UpdateSession($sessionInput: [UpdateSessionInput!]!) {
    updateSession(sessionInput: $sessionInput) {
      ...SessionUser
    }
  }
  ${SessionUser}
`;

export const stytchSuccessMutation = gql`
  mutation StytchSuccess {
    stytchSuccess {
      ...SessionUser
    }
  }
  ${SessionUser}
`;

export const linkMembershipMutation = gql`
  mutation LinkMebership(
    $emailPreviousHolder: String!
    $emailNextHolder: String!
  ) {
    linkMembership(
      emailPreviousHolder: $emailPreviousHolder
      emailNextHolder: $emailNextHolder
    ) {
      status
    }
  }
`;

export const updateMembershipMutation = gql`
  mutation UpdateMembershipByUserId(
    $userId: String
    $membershipInput: MembershipInput!
    $region: String
  ) {
    updateMembershipByUserId(
      userId: $userId
      membershipInput: $membershipInput
      region: $region
    ) {
      status
    }
  }
`;

export const getRegisteredUserByEmail = gql`
  query GetRegisteredUserByEmail($email: String!) {
    getRegisteredUserByEmail(email: $email) {
      isRegisteredRhUser
    }
  }
`;
